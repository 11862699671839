<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Application refund update</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Application refund update
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <div class="row">
                  <v-col cols="12" sm="12" md="3">
                    <v-text-field
                        label="Search by name"
                        v-model="search.candidate_info"
                        v-on:keyup.enter="searchUpdateRequest"
                        @input="search.candidate_info = $event !== null ? $event : ''"
                        clearable
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-menu v-model="request_date_menu" :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field  v-on:keyup.enter="searchUpdateRequest"
                                       v-model="search.request_date"
                                       label="Date of request" clearable
                                       prepend-inner-icon="mdi-calendar"
                                       readonly dense outlined
                                       v-bind="attrs"
                                       v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="search.request_date"
                          @input="request_date_menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>


                  <v-col cols="12" sm="12" md="2">
                    <v-btn
                        @click="searchUpdateRequest"
                        class="text-white btn btn-primary"
                        :loading="isLoading">
                      <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                      Search
                    </v-btn>
                  </v-col>
                </div>

              </div>

              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="! isLoading">
                <thead>
                <tr class="px-3">
                  <th v-if="currentUser && currentUser.access_type == 'head'">Branch</th>
                  <th>Date requested</th>
                  <th>Requested by</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th class="pr-3 text-center">Action</th>
                </tr>
                </thead>

                <template>
                  <tr v-for="(item, index) in update_requests" :key="index" v-if="update_requests.length>0">
                    <td class="px-2" v-if="currentUser && currentUser.access_type == 'head'">
                      {{item.branch_name}}
                    </td>
                    <td>
                        <span class="text-primary ml-2 font-weight-medium d-block font-size-lg">
                       {{ item.requested_date }}
                      </span>
                    </td>
                    <td>
                        <span class="ml-2 font-weight-bolder d-block font-size-lg">
                        {{ item.full_name }}
                      </span>
                    </td>
                    <td>
                        <span class="font-weight-black d-block font-size-lg">
                        {{ item.type_text }}
                      </span>
                    </td>
                    <td>
                           <span class="badge badge-success text-lg`"
                                 v-bind:class="{ 'badge-success': item.status=='approved', 'badge-danger': item.status=='rejected','badge-warning':item.status=='received' }">
                             {{ item.status_text }}
                           </span>
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="reviewRequest(item.id)" class="navi-link">
                                  <span class="navi-icon">

                                    <i class="fas fa-info-circle" style="color: #00008b"></i>
                                  </span>
                                <span class="navi-text">Review Request</span>
                              </a>
                            </b-dropdown-text>

                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="update_requests.length == 0">
                    <td class="text-center px-3" colspan="10">No items found.</td>
                  </tr>
                </template>

              </table>

              <b-pagination
                  v-if="update_requests.length > 0"
                  class="pull-right mt-7"
                  @input="getAllUpdateRequest"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  :disabled="isLoading"
                  last-number
              ></b-pagination>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
        v-model="dialog"
        width="900px" scrollable>
      <v-card>
        <v-card-title>
          <span class="h5 font-weight-regular">This request was made on <strong>{{updateRequestDetail.requested_date}}</strong> by <strong>{{updateRequestDetail.full_name}}</strong></span>
        </v-card-title>
        <v-card-text>
          <v-list>
            <template>
<!--              <v-list-item v-if="updateRequestDetail.content.length > 0" subheader v-for="(content,index) in updateRequestDetail.content">
                <v-list-item-title>
                  <div class="font-weight-regular " v-html="content"></div>
                </v-list-item-title>
              </v-list-item>-->
              <div>
                <p class="font-weight-regular " v-html="updateRequestDetail.content"></p>
              </div>
              <span class="h6 font-weight-regular" v-if="updateRequestDetail.content==0">No Data Changed</span>
            </template>

          </v-list>
          <v-container>
            <v-row>
              <v-col
                  cols="12">
                <ckeditor :config="editorConfig" v-model="remarks">
                </ckeditor>
              </v-col>
              <v-col cols="12">
                <v-list dense>
                  <v-subheader><span class="h5 font-weight-medium">Notes</span></v-subheader>
                  <v-list-item>
                    <v-list-item-content>
                      <p class="subtitle-1 font-weight-regular">Remarks made here will be sent by email to the Account Holder</p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <p class="subtitle-1 font-weight-regular">Selecting ‘Hold’ will send the State Office Remark to the Account Holder, and will change the request status to ‘On Hold’. The request can then be Approved or Not Approved at a later time.</p>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text class="cancel-btn"
              medium  x-large
              @click="refresh">
            Close
          </v-btn>
          <v-btn :loading="buttonLoading" x-large dark color="red" @click="disapprovedUpdateRequest" rounded>
            <v-icon right dark small>
              fas fa-times
            </v-icon> Not Approved
          </v-btn>
          <v-btn
              class="btn btn-primary text-white"
              dark :loading="buttonLoading"
              x-large rounded
              @click="approvedUpdateRequest">
            <v-icon right small dark>
              fas fa-check
            </v-icon> Approved
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import UpdateRequestService from "@/services/update-request/UpdateRequestService";
const updateRequest=new UpdateRequestService;
export default {
  data(){
    return{
      update_requests:[],
      search:{
        request_for:'refund',
        type:'',
        request_date:'',
      },
      total: null,
      perPage: null,
      page: null,
      isLoading:false,
      dialog:false,
      changed_data:[],
      updateRequestDetail:{},
      request_id:'',
      editorConfig: {
        versionCheck: false,
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ]
      },
      type:'',

      remarks:'',
      request_date_menu:false,
      buttonLoading:false,
    }
  },
  methods:{

    searchUpdateRequest()
    {
      this.getAllUpdateRequest();
    },

    getAllUpdateRequest()
    {
      this.search.type='agent';
      this.isLoading = true;
      updateRequest
          .paginate(this.search, this.page)
          .then(response => {
            this.update_requests=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.isLoading = false;
          })
          .catch((err) => {

          });
    },

    reviewRequest(itemId) {
      this.request_id=itemId;
      this.findDetail();
      this.dialog=true;
    },

    findDetail(){
      updateRequest
          .show(this.request_id)
          .then(response=>{
            this.updateRequestDetail=response.data.updateRequest;
            this.remarks=this.updateRequestDetail.remark;
            this.type=this.updateRequestDetail.type;
          })
    },

    approvedUpdateRequest()
    {
      this.buttonLoading=true;
      updateRequest
          .update(this.request_id,{'remark':this.remarks,'status':'approved','type':this.type})
          .then(response=>{
            this.refresh();
            this.getAllUpdateRequest();
            this.$snotify.success("Request Approved Successfully");
          }).catch(() => {}).finally(() => this.buttonLoading = false);
    },

    disapprovedUpdateRequest()
    {
      updateRequest
          .update(this.request_id,{'remark':this.remarks,'status':'rejected','type':this.type})
          .then(response=>{
            this.refresh();
            this.getAllUpdateRequest();
            this.$snotify.success("Request Approved Successfully");
          }).catch(() => {}).finally(() => this.buttonLoading = false);
    },

    refresh(){
      this.changed_data=[];
      this.remarks='';
      this.dialog=false;
      this.type='';
    },
  },
  mounted() {
    this.getAllUpdateRequest();
    if(this.request_id){
      this.findDetail();
    }
  }
}
</script>
